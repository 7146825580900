/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  IoLogoWhatsapp
} from 'react-icons/io';
import { motion } from 'framer-motion';
import logo from '../assets/logo.png';
import heroimg from '../assets/heroimg_mob.png';
import us from '../assets/us.png';
import uk from '../assets/uk.png';
import ireland from '../assets/ireland.png';
import canada from '../assets/canada.png';
import styles from './Home.module.scss';

const countries = [
  {
    id: 1,
    name: 'United States',
    image: us,
  },
  {
    id: 2,
    name: 'United Kingdom',
    image: uk,
  },
  {
    id: 3,
    name: 'Ireland',
    image: ireland,
  },
  {
    id: 4,
    name: 'Canada',
    image: canada,
  },
];

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleButtonClick = () => {
    const message = 'Hello! I am interested in knowing more about your service please.';
    window.open(`https://wa.me/${+2349136000996}?text=${message}`, '_blank');
  };

//   <img
//   src={heroPalm}
//   className={styles.homeImage}
//   alt="PalmShops.com hero"
// />

  return (
    <motion.div className={styles.container} style={{ overflow: 'hidden' }}>
      <motion.header
        className={styles.header}
        initial={{ x: -250 }}
        animate={{ x: 0 }}
      >
        <img
          src={logo}
          className={styles.headerImage}
          alt="GoStudy Educational Services"
        />
        <button
          type="button"
          className={styles.button}
          onClick={handleButtonClick}
        >
          {windowWidth < 767 ? "Let's Chat" : 'Get Started Now'}
        </button>
      </motion.header>
      <section className={styles.homeContainer}>
        <article className={styles.homeAbout}>
          <motion.div
            className={styles.homeText}
          >
            <motion.h1
              className={styles.homeTitle}
              initial={{ opacity: 0, y: -150 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2, delay: 0.5, stiffness: 500 }}
            >
              Unlock Your Global Future!
            </motion.h1>
            <p className={styles.homePara}>
              Discover World-Class Education Opportunities with Our Expert Guidance and Services at Gostudy Premium Education Consultancy.
            </p>
            <p className={styles.homePara}>
              With
              <span className={styles.tint}> GOSTUDY, </span>
              Your Study Abroad is with Ease!
            </p>
            <motion.button
              type="button"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              whileHover={{ scale: 0.9 }}
              transition={{ type: 'spring', stiffness: 150 }}
              className={styles.button}
              onClick={handleButtonClick}
            >
              Get Free Advice
            </motion.button>
          </motion.div>
          <img
            src={heroimg}
            className={styles.homeAboutImage}
            alt="GoStudy Educational Services"
          />
          <div className={styles.homeAboutText}>
            <h2 className={styles.homeAboutTitle}>
              Why People Choose Us
            </h2>
            <div className={styles.divider} />
            <ul className={styles.homeAboutDescription}>
              <li className={styles.homeAboutDescriptionItem}>
                Experienced counselors
              </li>
              <li className={styles.homeAboutDescriptionItem}>
                Wide range of universities and programs
              </li>
              <li className={styles.homeAboutDescriptionItem}>
                Visa assistance
              </li>
              <li className={styles.homeAboutDescriptionItem}>
                Accommodation support
              </li>
              <li className={styles.homeAboutDescriptionItem}>
                Scholarship opportunities
              </li>
              <li className={styles.homeAboutDescriptionItem}>
                Student post-arrival support/Pre-departure orientation
              </li>
              <li className={styles.homeAboutDescriptionItem}>
                Funding Aids/Proof of Funds
              </li>
              <li className={styles.homeAboutDescriptionItem}>
                Global network in major schools abroad
              </li>
            </ul>
          </div>
        </article>
      </section>
      <section className={styles.homeServices}>
        <div className={styles.split}>
          <article className={styles.homeServicesText}>
            <h2 className={styles.homeServicesTitle}>
              Ready to take your studies abroad?
            </h2>
            <p className={styles.homeServicesPara}>
              Get personalized advice and start your application with us today!
            </p>
            <button
              type="button"
              className={styles.button}
              onClick={handleButtonClick}
            >
              Chat with Us
              <IoLogoWhatsapp className={styles.chat} />
            </button>
          </article>
          <article className={styles.homeImage}>
            <h3 className={styles.subTitle}>You can have sure access to:</h3>
            <div className={styles.ImageContainer}>
              {
                countries.map((country) => (
                  <img
                    key={country.id}
                    src={country.image}
                    className={styles.ImageItem}
                    alt={country.name}
                  />
                ))
              }
            </div>
          </article>
        </div>
        <small className={styles.copyright}>
          <p className={styles.copyRight}>
            &copy;
            {new Date().getFullYear()}
            {' '}
            GoStudy Premium Education Consultancy
            <br />
            All Rights Reserved.
          </p>
        </small>
      </section>
  </motion.div>
  );
};

// <li className={styles.footerSocialLinkItem}>
//   <a href="https://www.twitter.com/palmshops" className={styles.footerSocialLink}>
//     <BsTwitterX className="icon" />
//   </a>
// </li>
// <li className={styles.footerSocialLinkItem}>
// <a href="https://www.instagram.com/palmshops" className={styles.footerSocialLink}>
//   <FaInstagram className={styles.icon} />
// </a>
// </li>

export default Home;